import {
  IAccountContractResult,
  IScheduleActive,
  IServiceType,
} from './interfaces/validate-account.interface';

export class ValidationAccount implements IAccountContractResult {
  private constructor(
    readonly ownAccount: string,
    readonly address: string,
    readonly phone: string,
    readonly account: string,
    readonly city: string,
    readonly vantimaxCoverage: boolean,
    readonly scheduleActive: IScheduleActive[],
    readonly bag: string,
    readonly sameDaySlot: string,
    readonly scheduleDate: string,
    readonly segmentedZone: string,
    readonly zone: string,
    readonly serviceTypes: IServiceType[],
  ) {}

  static fromJson(account: Partial<IAccountContractResult> = {}) {
    return new ValidationAccount(
      account?.ownAccount || '',
      account?.address || '',
      account?.phone || '',
      account?.account || '',
      account?.city || '',
      !!account?.vantimaxCoverage,
      account?.scheduleActive || [],
      account?.bag || '',
      account?.sameDaySlot || '',
      account?.scheduleDate || '',
      account?.segmentedZone || '',
      account?.zone || '',
      account?.serviceTypes || [],
    );
  }
}
