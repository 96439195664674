import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { UserStore } from '@core/stores';

export const authGuard: CanActivateFn = async (_, __) => {
  const userStore = inject(UserStore);
  const router = inject(Router);

  const isAuthActive = userStore.isAuthActive();

  if (!isAuthActive) {
    router.navigate(['/login'], { replaceUrl: true });
    return false;
  }

  return true;
};
