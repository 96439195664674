import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { environment } from '@env/environment';
import { AuthFacade } from './domain/facades';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private readonly authFacade = inject(AuthFacade);
  readonly title = 'sm-backoffice-sm';

  ngOnInit(): void {
    console.log('version: ', environment.version);
    this.authFacade.closeSessionTimer();
  }
}
