<article class="flex xs:flex-col lg:grid lg:grid-cols-12 h-screen w-screen max-w-screen-xxl">
  <section class="xs:w-full lg:col-span-6">
    <div class="relative xs:pt-8 xxs:pt-10 lg:py-8 lg:px-12 h-full">
      <div class="bg-gradient-to-b from-[#01549C] to-[#003258] xs:h-64 xxs:h-72 lg:h-full lg:height-screen rounded-2xl">
        <div class="flex flex-col gap-5 xl:gap-7 pt-10 xl:pt-12">
          <picture class="flex xs:justify-start lg:justify-end xs:pl-4 lg:pr-8">
            <img class="xs:w-3/12 lg:w-5/12" src="assets/images/arrow_home.png" alt="arrow home image" />
          </picture>
          <h2 class="xs:pl-4 lg:text-center lg:text-3xl xl:text-5xl xxl:text-6xl text-white font-bold">Agendamiento</h2>
        </div>
      </div>
      <picture class="absolute bottom-0 flex xs:right-0 lg:justify-start xxl:justify-center lg:mr-12 xs:w-9/12 lg:w-10/12">
        <img
          class="w-full"
          src="assets/images/hogares_tarjetas_experto.png"
          alt="experts home"
        />
      </picture>
    </div>
  </section>
  <section class="lg:col-span-6 flex flex-col gap-12 justify-center items-center">
    <picture class="xs:hidden lg:flex lg:justify-center">
      <img src="assets/images/vanti-logo-full.png" alt="vanti logo with copy" />
    </picture>
    <div class="border-black border rounded-2xl xs:w-11/12 lg:w-fit p-4 lg:p-10 xl:p-12 bg-white xs:mt-[-3rem] lg:mt-0 z-10">
      <div class="flex flex-col gap-4 lg:w-80 xxl:w-96">
        <h1
          class="text-center text-2xl font-semibold xl:text-3xl xxl:text-4xl xl:font-bold xxl:font-extrabold text-accent"
        >
          Inicio de sesión
        </h1>
        <p class="text-neutral-50 text-center">
          ¡Nos complace darte la bienvenida! <br />
          Ingresa y realiza tus agendamientos de la mejor manera en nuestra
          plataforma.
        </p>
        
        <button class="btn btn-primary" [disabled]="isFetching()" (click)="onSignIn()">
          {{ isFetching() ? 'Espera...' : 'Ingresar' }}
        </button>
      </div>
    </div>
  </section>
</article>
