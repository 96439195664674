import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map, Observable, switchMap, take } from 'rxjs';

import { environment } from '@env/environment';
import {
  MUTATION_LOGOUT,
  QUERY_AUTH_TOKEN,
  QUERY_URL_REDIRECT_SIGNIN,
} from '../gql/queries';
import { AuthTokenResponse, LoginRedirectResponse } from '../types/auth.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly apolloClient = inject(Apollo);

  getCurrentIp() {
    return from(
      fetch(environment.ipPublicUrl).then((data) => data.json()),
    ).pipe(map(({ ip }) => ip));
  }

  getAuthUrl() {
    return this.getCurrentIp().pipe(
      switchMap((ip) => {
        return this.apolloClient
          .query<LoginRedirectResponse>({
            query: QUERY_URL_REDIRECT_SIGNIN,
            variables: {
              body: {
                ip,
                referer: environment.callbackUrl,
              },
            },
          })
          .pipe(
            take(1),
            map((res) => ({
              data: res.data.redirectUrl,
            })),
          );
      }),
    );
  }

  validateToken(token: string): Observable<{ token: string }> {
    return this.apolloClient
      .query<AuthTokenResponse>({
        query: QUERY_AUTH_TOKEN,
        variables: {
          body: { token },
        },
      })
      .pipe(
        take(1),
        map((res) => res.data.authToken),
      );
  }

  logout() {
    return this.apolloClient.mutate({ mutation: MUTATION_LOGOUT });
  }
}
