import { inject, Injectable } from '@angular/core';
import { map, take } from 'rxjs';
import { Apollo } from 'apollo-angular';

import {
  GENERATE_SCHEDULES_REPORT,
  INIT_REPORT_FILTERS_DATA,
  SCHEDULES_REPORT,
} from '../data/gql';
import { IScheduleReport } from '../data/interfaces';
import { IReportFiltersInitData, TScheduleReportFilters } from '../data/types';

@Injectable({
  providedIn: 'root',
})
export class AdminReportsService {
  private readonly apolloClient = inject(Apollo);

  reportInitData() {
    return this.apolloClient
      .query<IReportFiltersInitData>({
        query: INIT_REPORT_FILTERS_DATA,
      })
      .pipe(
        take(1),
        map((res) => res.data),
      );
  }

  getScheduleReportData(filters: TScheduleReportFilters) {
    return this.apolloClient
      .mutate<{ consultSchedulesReport: IScheduleReport[] }>({
        mutation: SCHEDULES_REPORT,
        variables: { filters },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((res) => res.data.consultSchedulesReport),
      );
  }

  getDownloadScheduleReportData(filters: TScheduleReportFilters) {
    return this.apolloClient
      .query<{ generateSchedulesReport: string }>({
        query: GENERATE_SCHEDULES_REPORT,
        variables: { filters },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((res) => {
          const base64Content = res.data.generateSchedulesReport;

          const blob = this.base64ToBlob(
            base64Content,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          );
          return blob;
        }),
      );
  }

  private base64ToBlob(base64: string, mimeType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }
}
