import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { map } from 'rxjs';

import { UIStore, UserStore } from '@core/stores';
import { TModulesRole } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class UIFacade {
  private readonly router = inject(Router);
  private readonly userStore = inject(UserStore);
  private readonly uiStore = inject(UIStore);

  readonly isLazyLoading$ = toSignal(
    this.router.events.pipe(
      map((event) => {
        if (event instanceof RouteConfigLoadStart) {
          return true;
        } else if (event instanceof RouteConfigLoadEnd) {
          return false;
        }
        return false;
      }),
    ),
  );

  loadingStack$ = computed(() => {
    return this.uiStore.loadingStack();
  });

  isSidebarOpen$ = computed(() => {
    return this.uiStore.isSidebarOpen();
  });

  sidebarModules$ = computed(() => {
    const listItems: TModulesRole[] = ['agendar', 'capacidades', 'administrar'];

    const modules = listItems
      .map((item) => {
        const module = this.userStore
          .getMenuModules()
          .find((menu) => menu.key === item);

        return module;
      })
      .filter((item) => !!item);

    return modules;
  });

  public setLoadingStack(item: string) {
    this.uiStore.onSetLoadingStack(item);
  }

  public setIsSidebarOpen(isOpen: boolean) {
    this.uiStore.onSetIsSidebarOpen(isOpen);
  }
}
