import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { computed, inject } from '@angular/core';

import { AuthUser } from '@core/domain/entities';
import { environment } from '@env/environment';
import {
  createMenuModules,
  mapModulesPermissions,
} from '@core/domain/builders';
import { StorageService } from '@core/shared/services';

type TUserState = {
  token: string;
  xUser: string;
};

const initialState: TUserState = {
  token: '',
  xUser: '',
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withHooks((store) => {
    const storage = inject(StorageService);

    return {
      onInit: async () => {
        const token = await storage.getItem('token');
        patchState(store, { token });
      },
    };
  }),
  withState(initialState),
  withComputed(({ token }) => ({
    authUser: computed(() => {
      return AuthUser.fromToken(token());
    }),
    isAuthActive: computed(() => {
      const user = AuthUser.fromToken(token());

      if (!user.iat) {
        return false;
      }

      const currentTime = Math.floor(Date.now() / 1000);
      const limitInSeconds = environment.limitTokenInHours * 60 * 60;
      const isExpiredToken = currentTime - user!.iat > limitInSeconds;
      const isUserActive = [!!token(), !isExpiredToken].every(Boolean);

      return isUserActive;
    }),
    permissions: computed(() => {
      if (!token()) {
        return null;
      }

      const user = AuthUser.fromToken(token());

      return mapModulesPermissions(user.modules);
    }),
    getMenuModules: computed(() => {
      const user = AuthUser.fromToken(token());

      return createMenuModules(user.modules);
    }),
  })),
  withMethods((store) => {
    const storage = inject(StorageService);

    return {
      onClear: async () => {
        await storage.clear();
        patchState(store, initialState);
      },
      onSetToken: async (token: string) => {
        await storage.setItem('token', token);
        patchState(store, { token });
      },
      doSetXUser: (xUser: string) => {
        patchState(store, { xUser });
      },
    };
  }),
);
