import { TModulesRole, TSubModulesRole } from '../interfaces';
import { TSvgIcons } from '../types';

export const PAGE_MODULE_ROUTES: Record<TModulesRole, string> = {
  agendar: 'scheduler',
  capacidades: 'capacity',
  administrar: 'admin',
};

export const REVERSE_PAGE_MODULE_ROUTES: Record<string, TModulesRole> = {
  scheduler: 'agendar',
  capacity: 'capacidades',
  admin: 'administrar',
};

export const PAGE_SUB_MODULES_ROUTES: Record<TSubModulesRole, string> = {
  'administrar:zonas_segmentadas': 'admin/management-zones',
  'administrar:reportes': 'admin/reports',
};

export const MODULE_ICONS: Record<TModulesRole, TSvgIcons> = {
  agendar: 'calendar-content',
  capacidades: 'round-check',
  administrar: 'pencil',
};

export function getPagePath(path: string) {
  return path in PAGE_MODULE_ROUTES
    ? `/${PAGE_MODULE_ROUTES[path]}`
    : '/not-found';
}

export function getSubMenusPath(path: string) {
  return path in PAGE_SUB_MODULES_ROUTES
    ? `/${PAGE_SUB_MODULES_ROUTES[path]}`
    : '/not-found';
}

export function getIconPath(icon: string) {
  return icon in MODULE_ICONS ? MODULE_ICONS[icon] : ('' as TSvgIcons);
}
