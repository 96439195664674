import {
  getIconPath,
  getPagePath,
  getSubMenusPath,
  PAGE_MODULE_ROUTES,
  PAGE_SUB_MODULES_ROUTES,
  toTitleCase,
} from '../helpers';
import {
  IModuleItem,
  IModuleUser,
  IPayloadUserToken,
  IUser,
  TAppPermissions,
} from '../interfaces';

export function userBuilderFn(
  userAuth: Partial<IPayloadUserToken> = {},
): IUser {
  return {
    email: userAuth?.email ?? '',
    name: userAuth?.name ?? '',
    role: toTitleCase(userAuth?.permissions?.at(0)?.role ?? ''),
  };
}

export function createMenuModules(modules: IModuleUser[]): IModuleItem[] {
  return modules?.map(({ module, label, permissions, submodules }) => ({
    key: module,
    label: label,
    url: getPagePath(module),
    icon: getIconPath(module),
    permissions: permissions,
    submodules: submodules?.map((sm) => ({
      key: sm.submodule,
      label: sm.label,
      url: getSubMenusPath(sm.submodule),
      permissions: sm.permissions,
    })),
  }));
}

export function mapModulesPermissions(modules: IModuleUser[]) {
  const modulePermissions = new Map<string, TAppPermissions[]>();
  const subModulePermissions = new Map<string, TAppPermissions[]>();

  modules.forEach((m) => {
    modulePermissions.set(PAGE_MODULE_ROUTES[m.module], m.permissions);

    m.submodules?.forEach((sm) => {
      subModulePermissions.set(
        PAGE_SUB_MODULES_ROUTES[sm.submodule],
        sm.permissions,
      );
    });
  });

  return {
    modules: modulePermissions,
    submodules: subModulePermissions,
  };
}
