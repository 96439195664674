import { TAppPermissions, TRouteAutorizedModules } from '../interfaces';

export class RouteAutorized {
  constructor(readonly permissions: TRouteAutorizedModules) {}

  get modules() {
    return this.permissions.modules;
  }

  get submodules() {
    return this.permissions.submodules;
  }

  getRouteSplit(route: string) {
    const routeSplit = route?.split('/');
    const setRoutes = new Set(routeSplit);
    setRoutes.delete('');

    return Array.from(setRoutes);
  }

  hasAccessbyRole(routePath: string, permission: string) {
    const [route] = this.getRouteSplit(routePath);

    const routeItem = this.modules
      .get(route)
      ?.map((item) => item.toLocaleLowerCase());

    return (
      !!routeItem?.includes('all') ||
      !!routeItem?.includes(permission as TAppPermissions)
    );
  }

  validateRoute(routePath: string) {
    const [route] = this.getRouteSplit(routePath);
    return this.modules.has(route);
  }

  validateRouteBySubmodule(routePath: string, permission: string) {
    if (!this.submodules.has(routePath)) {
      return false;
    }

    const submodule = this.submodules
      .get(routePath)
      ?.map((item) => item.toLocaleLowerCase());

    return (
      submodule.includes('all') ||
      submodule.includes(permission as TAppPermissions)
    );
  }

  hasPermissions() {
    return !!this.modules.size;
  }

  getModulePermission(path: string) {
    return this.modules.get(path);
  }

  getFirstPath() {
    return [...this.modules.keys()]?.at(0);
  }
}
