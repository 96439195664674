import { Injectable, inject } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Observable,
  Subscription,
  catchError,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { IResponseMap } from '@core/domain/interfaces';
import {
  AppDocumentService,
  NotifyService,
  SessionTimerService,
} from '@core/shared/services';
import { AuthService } from '@core/modules/auth/services/auth.service';
import { UserStore } from '@core/stores';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  private readonly userStore = inject(UserStore);
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly routerActive = inject(ActivatedRoute);
  private readonly notify = inject(NotifyService);
  private readonly sessionTimer = inject(SessionTimerService);
  private readonly location = inject(Location);
  private readonly appDoc = inject(AppDocumentService);

  clearStore() {
    this.userStore.onClear();
  }

  getUrlAuth(): Observable<IResponseMap<null>> {
    return this.authService.getAuthUrl().pipe(
      tap((res) => {
        const { url } = res.data;

        this.appDoc.replace(url);
      }),
      map(() => ({ success: true, data: null })),
      catchError((err) => {
        return of({ success: false, message: err?.message, data: null });
      }),
    );
  }

  getUserInfoData(token: string) {
    return this.authService
      .validateToken(token)
      .pipe(map<{ token: string }, string>(({ token }) => token));
  }

  getTokenFromUrl() {
    return this.routerActive.queryParams
      .pipe(
        filter(({ token }) => !!token),
        map(({ token }) => token),
        switchMap((token) => this.getUserInfoData(token)),
      )
      .subscribe({
        next: async (token) => {
          await this.userStore.onSetToken(token);

          this.router.navigate(['/scheduler'], { replaceUrl: true });
        },
        error: async (err) => {
          this.notify.error('Espera!', err.message);
          await this.userStore.onClear();
          this.router.navigate(['/login'], { replaceUrl: true });
        },
      });
  }

  doSignOut() {
    return this.authService
      .logout()
      .pipe(
        take(1),
        map((data) => ({ ...data, success: true })),
        catchError(() => of({ success: false })),
      )
      .subscribe(() => {
        this.userStore.onClear();
        this.router.navigate(['/login'], { replaceUrl: true });
      });
  }

  closeSessionTimer() {
    let suscription: Subscription | null = null;

    this.location.onUrlChange((url) => {
      suscription?.unsubscribe();

      if (['/login'].includes(url)) {
        return;
      }

      suscription = this.sessionTimer
        .run()
        .pipe(take(1))
        .subscribe((event) => {
          this.notify.info('Fin de la sesión', event);
          this.doSignOut();
        });
    });
  }
}
