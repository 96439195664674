import { gql } from 'apollo-angular';

/** @deprecated */
export const QUERY_GET_URL_REDIRECT = gql`
  query RedirectUrl($loginRedirectRequestDto: RequestUrlDto!) {
    redirectUrl(loginRedirectRequestDto: $loginRedirectRequestDto) {
      url
    }
  }
`;

/** @deprecated */
export const QUERY_GET_AUTH_USER = gql`
  query AuthUser($authUserDto: AuthUserDto!) {
    authUser(authUserDto: $authUserDto) {
      token
    }
  }
`;

export const QUERY_URL_REDIRECT_SIGNIN = gql`
  query Query($body: RedirectUrlRefererDto!) {
    redirectUrl(body: $body) {
      url
    }
  }
`;

export const QUERY_AUTH_TOKEN = gql`
  query Query($body: SignInDto!) {
    authToken(body: $body) {
      token
    }
  }
`;

export const MUTATION_LOGOUT = gql`
  mutation Logout {
    logout {
      message
    }
  }
`;
