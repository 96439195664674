import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { map, pipe, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { DateTime } from 'luxon';

import {
  TAvailableCapabilityFormData,
  TScheduleDateForm,
  TSlot,
} from '@core/domain/entities/scheduler/types';
import {
  AccountContractBuilder,
  AccountInfo,
  AvailableCapabilityEntity,
  availableCapacityListEntity,
  ScheduleActiveEntity,
  scheduleActiveListEntity,
  ValidationAccount,
} from '@core/domain/entities/scheduler';
import { MONTHS, ScheduleTime } from '@core/shared/constants';
import { IScheduleFormData } from '@core/shared/types';
import { IAccountContractResult } from '@core/domain/entities/scheduler/interfaces';
import { TOption } from '@core/domain/types';
import { toTitleCase } from '@core/domain/helpers';
import { CapabilitiesService } from '@core/modules/capacities/services';
import { NotifyService } from '@core/shared/services';
import { TCapabilitiesByOperation } from '@core/modules/capacities/data/types';

export enum LoadingSchedule {
  CAPABILITIES_BY_SERVICE_TYPE = 'CAPABILITIES_BY_SERVICE_TYPE',
  CONSULT_ACCOUNT_CONTRACT = 'CONSULT_ACCOUNT_CONTRACT',
}

type ScheduleState = {
  accountContract: AccountContractBuilder | null;
  searchAccountInfo: ValidationAccount | null;
  fetchStack: LoadingSchedule[];
  availableCapabilities: AvailableCapabilityEntity[];
  scheduleDateForm: TScheduleDateForm | null;
};

const initialState: ScheduleState = {
  accountContract: null,
  searchAccountInfo: null,
  fetchStack: [],
  availableCapabilities: [],
  scheduleDateForm: null,
};

export const ScheduleStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store) => ({
    accountInfo: computed<AccountInfo>(() => {
      const account = store.searchAccountInfo();

      return AccountInfo.fromJson({
        ownAccount: account?.ownAccount,
        address: account?.address,
        phone: account?.phone,
        account: account?.account,
        city: account?.city,
        vantimax_coverage: account?.vantimaxCoverage,
        segmentedZone: account?.segmentedZone,
      });
    }),
    currentSchedule: computed<ScheduleActiveEntity | null>(() => {
      const listSchedule = scheduleActiveListEntity(
        store.searchAccountInfo()?.scheduleActive,
      );

      return listSchedule?.at(0) || null;
    }),
    scheduleActiveList: computed<ScheduleActiveEntity[]>(() => {
      return scheduleActiveListEntity(store.searchAccountInfo().scheduleActive);
    }),
    currentScheduleDate: computed<string>(() => {
      const dateItem = ScheduleActiveEntity.fromJson(
        store.searchAccountInfo()?.scheduleActive?.at(0),
      )?.scheduleDate;

      return `${dateItem?.day} de ${MONTHS[dateItem?.month]} de ${dateItem?.year}`;
    }),
    currentPhone: computed<string>(
      () => store.accountContract()?.scheduling_phone,
    ),
    currentScheduleTime: computed<string>(() => {
      const currentSchedule = ScheduleActiveEntity.fromJson(
        store.searchAccountInfo()?.scheduleActive?.at(0),
      );
      if (!currentSchedule?.scheduleTime) {
        return '';
      }

      return ScheduleTime[currentSchedule.scheduleTime];
    }),
    listSchedulerServiceTypes: computed<TOption[]>(() => {
      return store.searchAccountInfo()?.serviceTypes.map((st) => ({
        label: toTitleCase(st.serviceType),
        value: st.id,
      }));
    }),
    buildFormDataSchedule: computed<IScheduleFormData>(() => ({
      ...store.accountContract(),
      service_type: store.scheduleDateForm()?.serviceSchedule,
      time_slot: store.scheduleDateForm()?.journeyControl as TSlot,
      service_date: store.scheduleDateForm()?.date?.toISO(),
    })),
  })),
  withMethods(
    (
      store,
      capabilitiesService = inject(CapabilitiesService),
      notify = inject(NotifyService),
    ) => {
      return {
        doClear: () => {
          patchState(store, initialState);
        },
        doSetAccountContract: (account: AccountContractBuilder | null) => {
          patchState(store, { accountContract: account });
        },
        doSetAccountInfo: (account: IAccountContractResult | null) => {
          patchState(store, { searchAccountInfo: account });
        },
        doSetAvailableCapabilities: (
          availableCapabilities: AvailableCapabilityEntity[],
        ) => {
          patchState(store, { availableCapabilities });
        },
        doSetScheduleDateForm: (scheduleDateForm: TScheduleDateForm | null) => {
          patchState(store, { scheduleDateForm });
        },
        doToggleFetching: (item: LoadingSchedule) => {
          patchState(store, ({ fetchStack }) => ({
            fetchStack: fetchStack.includes(item)
              ? fetchStack.filter((fs) => fs !== item)
              : [...fetchStack, item],
          }));
        },
        showAvailableCapability: (
          date: DateTime,
          slot: TSlot,
        ): TAvailableCapabilityFormData => {
          const item = store
            .availableCapabilities()
            .find(
              (ac) =>
                ac.date.toFormat('yyyy-MM-dd') === date.toFormat('yyyy-MM-dd'),
            );

          return {
            date: item.date,
            total_capacity: item.totalCapacity,
            isActive: item.isActive,
            time_slots_info: item.timeSlotsInfo.find((ts) => ts.slot === slot),
          };
        },
        getCapabilitiesByOperation: rxMethod<string>(
          pipe(
            tap(() =>
              patchState(store, ({ fetchStack }) => ({
                fetchStack: [
                  ...fetchStack,
                  LoadingSchedule.CAPABILITIES_BY_SERVICE_TYPE,
                ],
              })),
            ),
            map<string, TCapabilitiesByOperation>((filter) => {
              const account = store.searchAccountInfo();
              const { operation = '' } = store
                .searchAccountInfo()
                ?.serviceTypes?.find((ls) => ls.id === filter);

              return {
                bag: account.bag,
                date: account.scheduleDate,
                sameDaySlot: account.sameDaySlot,
                segmentedZone: account.segmentedZone,
                zone: account.zone,
                operation,
              } as TCapabilitiesByOperation;
            }),
            switchMap((operation) => {
              return capabilitiesService
                .getCapabilitiesByOperation(operation)
                .pipe(
                  tapResponse({
                    next: (resp) => {
                      patchState(store, {
                        availableCapabilities:
                          availableCapacityListEntity(resp),
                      });
                    },
                    error: (error: Error) => {
                      notify.error('Espera', error?.message);
                    },
                    complete: () =>
                      patchState(store, ({ fetchStack }) => ({
                        fetchStack: fetchStack.filter(
                          (fs) =>
                            fs !== LoadingSchedule.CAPABILITIES_BY_SERVICE_TYPE,
                        ),
                      })),
                  }),
                );
            }),
          ),
        ),
      };
    },
  ),
);
