import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { AdminReportsService } from '@core/modules/admin/services';
import { onCatchError } from '../helpers';
import { IResponseMap } from '../interfaces';
import {
  IReportFiltersInitData,
  TScheduleReportFilters,
} from '@core/modules/admin/data/types';
import { NotifyService } from '@core/shared/services';
import { IScheduleReport } from '@core/modules/admin/data/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AdminReportsFacade {
  private readonly reportService = inject(AdminReportsService);
  private readonly notify = inject(NotifyService);

  getInitReportsData(): Observable<IResponseMap<IReportFiltersInitData>> {
    return this.reportService.reportInitData().pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError(onCatchError),
    );
  }

  consultScheduleReport(
    filters: TScheduleReportFilters,
  ): Observable<IResponseMap<IScheduleReport[]>> {
    return this.reportService.getScheduleReportData(filters).pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError(onCatchError),
      tap((resp) => {
        if (!resp.success) {
          this.notify.error('Espera!', (resp as any).message);
        }
      }),
    );
  }

  downloadScheduleReport(
    filters: TScheduleReportFilters,
  ): Observable<IResponseMap<{ blob: Blob; filename: string }>> {
    return this.reportService.getDownloadScheduleReportData(filters).pipe(
      map((blob) => {
        const date = new Date();
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`; // Formato YYYY-MM-DD
        const filename = `reporte-agendas-${formattedDate}.xlsx`;

        return {
          success: true,
          data: { blob, filename },
        };
      }),
      catchError((error) => {
        this.notify.error('Espera!', error.message || 'Ocurrió un error');
        return of({
          success: false,
          data: null,
        });
      }),
      tap((resp) => {
        if (!resp.success) {
          this.notify.error(
            'Espera!',
            (resp as any).message || 'Error desconocido',
          );
        }
      }),
    );
  }
}
