import { Component, inject, OnInit, signal } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  RecaptchaModule,
  RecaptchaV3Module,
  ReCaptchaV3Service,
} from 'ng-recaptcha';
import { MatSelectModule } from '@angular/material/select';

import { NotifyService } from '@core/shared/services';
import { AuthFacade } from '@core/domain/facades';

@Component({
  selector: 'page-login',
  standalone: true,
  imports: [RecaptchaModule, RecaptchaV3Module, MatSelectModule],
  templateUrl: './login.component.html',
  styleUrl: './login.styles.css',
})
export class LoginComponent implements OnInit {
  private readonly authFacade = inject(AuthFacade);
  private readonly recaptchaService = inject(ReCaptchaV3Service);
  private readonly notify = inject(NotifyService);
  public readonly isFetching = signal(false);

  ngOnInit() {
    this.authFacade.clearStore();
  }

  getReCaptchaToken() {
    return this.recaptchaService.execute('init_form_signin').pipe(
      map((resp) => ({ success: true, data: resp })),
      catchError((err) => of({ success: false, message: err.message })),
    );
  }

  onSignIn() {
    this.isFetching.set(true);
    return this.getReCaptchaToken()
      .pipe(
        switchMap(() => {
          return this.authFacade.getUrlAuth();
        }),
      )
      .subscribe({
        next: ({ success, message }) => {
          if (!success) {
            this.notify.error('Espera', message!);
          }
        },
        complete: () => this.isFetching.set(false),
      });
  }
}
