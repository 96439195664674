import { gql } from 'apollo-angular';

export const INIT_REPORT_FILTERS_DATA = gql`
  query InitReportQuery {
    listOperations {
      id
      name
      label
    }
    zoneList {
      id
      code
      name
    }
  }
`;

export const SCHEDULES_REPORT = gql`
  mutation ConsultSchedulesReport(
    $filters: FiltersScheduleReportDTO!
  ) {
    consultSchedulesReport(filters: $filters) {
      id
      filial
      operation
      segmented_zone
      bag
      account_contract
      scheduling_name
      scheduling_phone
      technical_referral
      data_policy_accepted
      process_accepted
      will_attend
      attendant_name
      attendant_phone
      visitor_data_policy_accepted
      schedule_date
      time_slot
      coverage_vantimax
      coverage_type_vantimax
      pipe
      is_canceled
      owner_account
      document_numberowner_account
      address_account
      province
      city
      supply_situation
      customer_type
      reason_suspension
      fraud
      province_code
      city_code
      counter_rpo
      created_at
      updated_at
      service_type {
        id
        service_type
        status
        created_at
        updated_at
      }
      person_income_sap
      role
      who_cancelled
      malla
    }
  }
`;

export const GENERATE_SCHEDULES_REPORT = gql`
  query Query($filters: FiltersScheduleReportDTO!) {
    generateSchedulesReport(filters: $filters)
  }
`;
