import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly sessionStorage = inject(DOCUMENT).defaultView.sessionStorage;

  async getItem(key: string) {
    const data = JSON.parse(this.sessionStorage.getItem(key) as string);
    return data;
  }

  async setItem<T>(key: string, item: T) {
    const data = JSON.stringify(item);
    this.sessionStorage.setItem(key, data);
  }

  async clear() {
    this.sessionStorage.clear();
  }
}
