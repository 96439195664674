import { gql } from 'apollo-angular';

export const SEARCH_ACCOUNT_DATA_CONTRACT = gql`
  query Query($contract: ValidateAccountContractDto!) {
    validateAccountContractData(contract: $contract) {
      status
      message
      code
      data {
        ownAccount
        address
        phone
        account
        city
        vantimaxCoverage
        scheduleActive {
          id
          schedule_date
          type_service
          schedule_time
          cancelationDetails {
            canCancel
            message
          }
        }
        bag
        sameDaySlot
        scheduleDate
        segmentedZone
        zone
        serviceTypes {
          id
          serviceType
          label
          operation
        }
      }
    }
  }
`;
