import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { getPagePath } from '@core/domain/helpers';
import { RouteAutorized } from '@core/domain/entities';
import { UserStore } from '@core/stores';

export const authorizedGuard: CanActivateFn = (_, state) => {
  const userStore = inject(UserStore);
  const router = inject(Router);
  const allowPath = state.url;

  const routerValidator = new RouteAutorized(userStore.permissions());

  const [path] = routerValidator.getRouteSplit(allowPath);

  if (!routerValidator.hasPermissions()) {
    router.navigate(['/login'], { replaceUrl: true });
    return false;
  }

  if (!routerValidator.validateRoute(allowPath)) {
    return false;
  }

  if (!routerValidator.getModulePermission(path)?.length) {
    router.navigate([getPagePath(routerValidator.getFirstPath())]);

    return false;
  }

  return true;
};
