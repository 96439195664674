import { inject, Injectable } from '@angular/core';
import { map, take } from 'rxjs';
import { Apollo } from 'apollo-angular';

import {
  TAccountContract,
  TScheduleDateForm,
  TScheduleServiceOption,
} from '@core/domain/entities/scheduler/types';
import {
  LIST_CREATE_SCHEDULE_DATA,
  REQUEST_OTP_CODE,
  CONFIRM_OTP_CODE,
  CREATE_NEW_SCHEDULE_BY_ACCOUNT,
  SOFT_DELETE_SCHEDULE,
  SEARCH_ACCOUNT_DATA_CONTRACT,
} from '@core/modules/scheduler/data/gql';
import {
  TConfirmOTP,
  TGenerateOTPCode,
  TResponseData,
} from '@core/shared/types';
import { IAccountContractResponseData } from '@core/domain/entities/scheduler/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  private readonly apolloClient = inject(Apollo);

  getAccountContractSchedules(contract: TAccountContract) {
    return this.apolloClient
      .query<{ validateAccountContractData: IAccountContractResponseData }>({
        query: SEARCH_ACCOUNT_DATA_CONTRACT,
        variables: { contract },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((resp) => resp.data.validateAccountContractData),
      );
  }

  getCreateScheduleOptions() {
    return this.apolloClient
      .query<{ listAccountServiceType: TScheduleServiceOption[] }>({
        query: LIST_CREATE_SCHEDULE_DATA,
      })
      .pipe(
        take(1),
        map((resp) => resp.data.listAccountServiceType),
      );
  }

  generateOTPCode(phone: string) {
    return this.apolloClient
      .mutate<{ generateOTP: TGenerateOTPCode }>({
        mutation: REQUEST_OTP_CODE,
        variables: {
          body: {
            phone,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((resp) => resp.data.generateOTP),
      );
  }

  confirmOTPCode(otp: string, phone: string) {
    return this.apolloClient
      .query<{ validateOTP: TConfirmOTP }>({
        query: CONFIRM_OTP_CODE,
        variables: {
          body: {
            otp,
            phone,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((resp) => resp.data.validateOTP),
      );
  }

  createSchedule(formData: TScheduleDateForm) {
    return this.apolloClient
      .mutate<{ createSchedule: { id: string } }>({
        mutation: CREATE_NEW_SCHEDULE_BY_ACCOUNT,
        variables: {
          contract: formData,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((resp) => resp.data.createSchedule),
      );
  }

  cancelSchedule(scheduleId: string) {
    return this.apolloClient
      .mutate<{ softDeleteSchedule: TResponseData }>({
        mutation: SOFT_DELETE_SCHEDULE,
        variables: {
          scheduleId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        take(1),
        map((resp) => resp.data.softDeleteSchedule),
      );
  }
}
