import { Component, OnInit, inject } from '@angular/core';

import { AuthFacade } from '@core/domain/facades';

@Component({
  selector: 'page-auth-verify',
  standalone: true,
  imports: [],
  templateUrl: './auth-verify.component.html',
})
export class AuthVerifyComponent implements OnInit {
  private readonly authFacade = inject(AuthFacade);

  ngOnInit(): void {
    this.authFacade.getTokenFromUrl();
  }
}
