import { computed, inject, Injectable } from '@angular/core';
import { catchError, map, Observable, switchMap, tap } from 'rxjs';

import { IResponseMap } from '@core/domain/interfaces';
import {
  AccountContractBuilder,
  ErrorAccount,
  ValidationAccount,
} from '@core/domain/entities/scheduler';
import {
  TAccountContract,
  TScheduleDateForm,
  TSearchAccountContract,
} from '@core/domain/entities/scheduler/types';
import { SchedulerService } from '@core/shared/services/scheduler';
import {
  TFacadeResponse,
  TResponseData,
} from '@core/shared/types/general.type';
import { TConfirmOTP, TGenerateOTPCode } from '@core/shared/types';
import { LoadingSchedule, ScheduleStore } from '@core/stores';
import { onCatchError } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class SchedulerFacade {
  private readonly schedulerService = inject(SchedulerService);
  private readonly store = inject(ScheduleStore);

  isFetchingSearchAccount$ = computed(() => {
    return this.store
      .fetchStack()
      .includes(LoadingSchedule.CONSULT_ACCOUNT_CONTRACT);
  });

  toggleFetchingSearchAccount() {
    this.store.doToggleFetching(LoadingSchedule.CONSULT_ACCOUNT_CONTRACT);
  }

  makeAccountContractRequest(account: Partial<TSearchAccountContract>) {
    const formData = AccountContractBuilder.fromJson(account);
    this.store.doSetAccountContract(formData);

    return this.getAccountContractSearch(formData).pipe(
      tap(({ success, data }) => {
        if (success) {
          this.store.doSetAccountInfo(data.accountInfo);
        }
      }),
    );
  }

  getAccountContractSearch(
    contract: TAccountContract,
  ): Observable<
    IResponseMap<{ accountInfo: ValidationAccount; errorAccount: ErrorAccount }>
  > {
    return this.schedulerService.getAccountContractSchedules(contract).pipe(
      map((resp) => {
        const { data, ...rest } = resp;

        return {
          success: true,
          data: {
            accountInfo: ValidationAccount.fromJson(data),
            errorAccount: ErrorAccount.fromJson(rest),
          },
        };
      }),
      catchError(onCatchError),
    );
  }

  generateOTPCodeByPhone(
    phone: string,
  ): Observable<TFacadeResponse<TGenerateOTPCode>> {
    return this.schedulerService.generateOTPCode(phone).pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError(onCatchError),
    );
  }

  validateOTPCode(
    otp: string,
    phone: string,
  ): Observable<TFacadeResponse<TConfirmOTP>> {
    return this.schedulerService.confirmOTPCode(otp, phone).pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError(onCatchError),
    );
  }

  createScheduleByAccount(
    formData: TScheduleDateForm,
  ): Observable<IResponseMap<string>> {
    return this.schedulerService.createSchedule(formData).pipe(
      switchMap((resp) => {
        const account = this.store.accountContract();
        return this.makeAccountContractRequest({
          ...account,
        } as unknown as TSearchAccountContract).pipe(map(() => resp.id));
      }),
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError(onCatchError),
    );
  }

  cancelSchedule(scheduleId: string): Observable<IResponseMap<TResponseData>> {
    return this.schedulerService.cancelSchedule(scheduleId).pipe(
      map((resp) => {
        return {
          success: true,
          data: resp,
        };
      }),
      catchError(onCatchError),
    );
  }
}
